<template>
  <div class="shareCon">
    <div class="shareInside">
      <span class="shareTitle">分享</span>
      <label class="shareLogo">
        <div class="bdsharebuttonbox">
          <ul class="gb_resItms">
            <label class="logoWeixin opacity"><a title="分享到微信" href="javascript:;" class="logoWeixin"
                data-cmd="weixin"></a></label>
            <label class="logoWeibo opacity"><a title="分享到新浪微博" href="javascript:;" class="logoWeibo"
                @click="goWeibo"></a></label>
          </ul>
        </div>
      </label>
    </div>
  </div>
</template>
<script>
  export default {
    methods: {
      goWeibo() {
        var currUrl = window.location.href;
        window.open('http://service.weibo.com/share/share.php?url=' + encodeURIComponent(currUrl) + '&title=' + document
          .title + '&appkey=1343713053&searchPic=true');
      },
    }
  }
</script>
<style lang="scss" scoped>
  .shareCon {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shareTitle {
    font-size: 24px;
    color: #adadad;
    margin-right: 40px;
  }

  .bdsharebuttonbox a {
    width: 70% !important;
    height: 93% !important;
    opacity: 0;
  }

  .bdsharebuttonbox a:hover {
    opacity: 0 !important;
  }

  .logoWeixin {
    display: inline-block;
    width: 86px;
    height: 86px;
    background-image: url(../../assets/img/sharelogo1.png);
    background-size: 100% 100%;
    margin-right: 60px;
    cursor: pointer;
  }

  .shareLogo {
    display: inline-block;
    vertical-align: middle;
  }

  .logoWeibo {
    display: inline-block;
    width: 86px;
    height: 86px;
    background-image: url(../../assets/img/sharelogo2.png);
    background-size: 100% 100%;
    cursor: pointer;
  }

  .opacity:hover {
    opacity: 0.8;
  }
</style>
